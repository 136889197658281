<template>
  <card shadow style="padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; height: 180px;">
    <form @submit.prevent="submit" id="form-field">
      <all-field :data="dataTracking && dataTracking.Left.Data[0].Data"></all-field>
      <b-button class="w-full" type="is-primary" native-type="submit">{{dataTracking && dataTracking.Left.Data[0].SubmitButton || "Save"}}</b-button>
      <b-modal
        :active="result"
        has-modal-card
      >
        <div class="relative">
          <div @click="result = null" class="absolute bg-red-400 cursor-pointer" style="border-radius: 20px; top: -10px; right: 4px; z-index: 100 !important;">
              <b-icon icon="close" class="rounded-full p-4 my-auto" custom-size="mdi-24px" type="is-white"></b-icon>
          </div>
        </div>
        <div class="modal-card w-1/2">
          <section class="modal-card-body mx-4 rounded-md">
            <list-item v-for="(v, k) in result" :key="k" :data="{Text: k, BorderWidth: 0, Class: { 'font-bold': k === 'Total' }}" class="pt-1">
              <template #righttext>{{ v | apiPath == '/livetracking' ? user.CurrencyObj : currency(user.CurrencyObj) }}</template>
            </list-item>
          </section>
        </div>
      </b-modal>
    </form>
  </card>
</template>

<script>
import img from '@/assets/ipost/calc.png'
import Card from './Card'
export default {
  components: {
    Card,
    AllField: () => import('@/components/form/AllField')
  },
  props: {
    id2: String,
    dataTracking: Object
  },
  data () {
    return {
      img,
      result: null,
      errors: {}
    }
  },
  computed: {
     liveTrackingImg () {
      return this.company.MascotImages.LiveTracking
    }
  },
  methods: {
    handleSubmitError (err) {
      if (err.errors) {
        this.errors = err.errors
      } else {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: err.message,
          type: 'is-danger'
        })
      }
    },
    async submit () {
      this.$store.commit('service/SET_CONDITION_NOTIF', true)
      const loading = this.$buefy.loading.open()
      try {
        const data = this.getData('form-field')
        this.result = await this.$baseApi.post('/livetracking', data)
        if (this.isSm) {
          this.$nextTick(() => {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
          })
        }
      } catch (err) {
        this.handleSubmitError(err)
      } finally {
        loading.close()
      }
    }
  }
}
</script>
